<template>
	<div class="section-banner">
		<Position position="topbanner" v-if="$route.name == 'Index'" />
		<Position position="alltop" v-else />
	</div>
	<div class="install" v-if="showInstall">
		<div class="btn" :style="{'background-image':'url('+$Svg('close.svg')+')'}" @click="showInstall=false"></div>
		<img :src="$Svg('iosinstall.png')" />
	</div>
	<div class="clear"></div>
	<Position position="head" />
	<div class="clear"></div>
	<header>
		<div class="layout">
			<div class="logo" @click="$router.push({name:'Index'})">
				<!-- <img class="ico" :src="$store.state.siteInfo.Logo" v-if="$store.state.siteInfo.Logo" />
				<img class="ico" :src="$Svg('ico.svg')" v-else /> -->
				<img class="ico" :src="$Svg('ico.svg')" />
				<div>
					<img :src="$Svg('name.png')" />
					<span>{{domain}}</span>
				</div>
			</div>
			<div class="input" @click="$router.push({name:'Search'})">
				<span class="search" >
					<img :src="$Svg('search.svg')" />
				</span>
			</div>
			<div class="menber">
				<img :src="$Svg('phone.svg')" @click="FN.toApp()" />
				<font>下载APP</font>
			</div>
		</div>
		<slot></slot>
	</header>
	<div class="clear"></div>
	<Position position="nextdown" />
	<div class="clear"></div>
	<Position position="menudown" />
	<div class="clear"></div>
	<div class="three"><Position position="topthree" /></div>
	<div class="clear"></div>
	<Position position="mytop" v-if="$route.name != 'Index'" />
	<div class="clear"></div>
</template>
<script setup>
	import { reactive, toRefs, ref, getCurrentInstance, onMounted, nextTick, watch } from "vue";
	import { useRouter } from "vue-router";
	let router = useRouter();

	import { useStore } from "vuex";
	const store = useStore();

	//获取上下文
	const currentInstance = getCurrentInstance()
	const { $ajax } = currentInstance.appContext.config.globalProperties

	// api
	import useApi from  '@/lib/js/api.js'
	let api = useApi($ajax)

	const state = reactive({
		showMenber:false,
		showInstall:false,
		domain:"",
		app:{
			"apkVersion":"",
			"apkUri":"",
			"apkCode":1,
			"iosVersion":"",
			"iosUri":"",
			"iosCode":1
		},
	})
	const { showMenber,domain,showInstall } = toRefs(state)
	const FN = {
		getAppVersion(){
			api.getAppVersion().then(res=>{
				state.app = res.data || {}
			}).catch(err=>{
				console.dir(err.message)
			})
		},

		getHostName(){
			if(store.state.siteInfo.Forever){
				state.domain = store.state.siteInfo.Forever
			}else if(store.state.siteInfo.SiteURL){
				state.domain = store.state.siteInfo.SiteURL
			}else{
				state.domain = location.hostname
			}
		},

		toApp(){
			if(android && state.app.apkUri){
				window.open(state.app.apkUri,"_blank")
				return
			}
			if(iphone && state.app.iosUri){
				window.open(state.app.iosUri,"_blank")
				state.showInstall = true
				return
			}
			alert("敬请期待")
		}
	}

	onMounted(() => {
		FN.getHostName()
		FN.getAppVersion()
		// state.showInstall = true
	})

	// watch(()=>router,(n,o)=>{
	// 	let route = n.currentRoute.value || {}
	// 	switch(route.name){
	// 		case "CateList":
	// 		case "Discovery":
	// 		case "Topic":
	// 		case "People":
	// 			state.showMenber = false
	// 		break;
	// 		default:
	// 			state.showMenber = true
	// 	}
	// },{immediate:true,deep:true})
</script>
<style lang='scss' scoped>
.install{
	position: fixed;
	z-index: 99;
	width:100vw;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	font-size: 0;
	padding: 0;
	.btn{
		position: absolute;
		right: 20px;
		top: 20px;
		width:40px;
		height:40px;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 1;
		background-size: contain; 
	}
	img{
		background: rgba(0,0,0,0.9);
		position: relative;
		width: 100vw;
	}
}
header{
	width: 100%;
	box-shadow: 0px 4px 20px 0px rgba(78,78,78,0.1);
	position: sticky;
	top: 0;
	z-index: 10;
	background-image: var(--theme);
	.layout{
		margin:0 auto;
		position: relative;
		display: grid;
		grid-template-columns: auto 130px 50px;
		grid-gap: 10px;
		padding: 16px 0 10px 0;
		.logo{
			display: flex;
			align-items: center;
			padding-left: 10px;
			position: relative;
			.ico{
				width: 40px;
				height: 40px;
				object-fit: contain;
			}
			div{
				position: absolute;
				top: 0;
				left: 30%;
				width:70%;
				img{
					width:90%;
					display: block;
					height: auto;
				}
				span{
					width:90%;
					display: block;
					overflow: hidden;
					white-space: nowrap;
					text-align: center;
					color:#fff;
					font-size: 16px;
					font-weight: bold;
				}
			}
		}
		.input{
			position: relative;
			width: 100%;
			height: 34px;
			background: #FFFFFF;
			border-radius: 20px;
			padding:0 10px;
			color: #999;
			input{
				outline: none;
				width:72%;
				height: 34px;
				border: 0;
				border-radius: 5px;
				display: none;
			}
			.search{
				position: absolute;
				top: 2px;
				right: 2px;
				height: 30px;
				width: 30px;
				background-image: var(--search);
				border-radius: 50%;
				font-size: 14px;
				display: flex;
				align-items: center;
				color: #fff;
				cursor: pointer;
				img{
					margin-left: 8px;
				}
			}
		}
		.menber{
			cursor: pointer;
			font-size: 12px;
			color: #fff;
			text-align: center;
			position: relative;
			padding-right: 5px;
			img{
				width: 26px;
			}
			font{
				position:absolute;
				top: 70%;
				padding-right: 5px;
				left: 0;
				width:100%;
				display: inline-block;
				font-size: 0.625rem;
			}
		}
	}
}
</style>